import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DescriptionProps } from './types';
import styles from './styles';

const Description: React.FC<DescriptionProps> = ({ action }) => {
    const { t } = useTranslation('dashboard', {
        keyPrefix: 'index.action.card.description',
    });

    const {
        subtitle_1,
        scheduled_start_date,
        scheduled_end_date,
        actual_start_date,
        actual_end_date,
    } = action;

    return (
        <>
            <div style={styles.subtitleContainer}>
                {subtitle_1}
            </div>
            <div style={styles.timeInfoRow}>
                <div style={styles.firstInfoColumn}>
                    <b>{t('should_start')}:</b>&nbsp;
                    {dayjs(scheduled_start_date).utc().format('HH:mm')}
                </div>
                <div>
                    <b>{t('is_start')}:</b>&nbsp;
                    {actual_start_date ? dayjs(actual_start_date).utc().format('HH:mm') : '-'}
                </div>
            </div>
            <div style={styles.timeInfoRow}>
                <div style={styles.firstInfoColumn}>
                    <b>{t('should_end')}:</b>&nbsp;
                    {dayjs(scheduled_end_date).utc().format('HH:mm')}
                </div>
                <div>
                    <b>{t('is_end')}:</b>&nbsp;
                    {actual_end_date ? dayjs(actual_end_date).utc().format('HH:mm') : '-'}
                </div>
            </div>
        </>
    );
};

export default Description;
