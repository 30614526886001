import React from 'react';
import { Dayjs } from 'dayjs';

import TimeView from '../../TimeView';
import IdentityCard from '../IdentityCard';

import { DailyActionResource } from '../../../../../types';

type FirstRowProps = {
    action: DailyActionResource;
    time: Dayjs;
    onTimeChange: (time: Dayjs) => void;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
};

const FirstRow: React.FC<FirstRowProps> = ({
    action,
    time,
    onTimeChange,
}) => {
    return (
        <div style={styles.container}>
            <IdentityCard
                title={action.title}
                icon={action.icon}
                subtitle_1={action.subtitle_1}
            />
            <TimeView value={time} onChange={onTimeChange}/>
        </div>
    );
};

export default FirstRow;
