const styles = {
    container: {
        width: 150,
        height: 150,
        marginRight: 20,
        backgroundColor: '#fef7f6',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        userSelect: 'none' as 'none',
    }
};

export default styles;
