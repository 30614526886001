const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-end',
    },
};

export default styles;
