import React from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSchemaField } from '@formily/react';
import {
    Form,
    FormLayout,
    FormItem,
    Input,
    Select,
    ArrayItems,
    Space,
} from '@formily/antd-v5';
import { StartupRole } from '../../../../../types';
import {
    upsertStartupRole,
} from '../../../../../utils/network/api/v1';

type RoleFormProps = {
    roleId?: number;
    buttons: (onSubmit: (data: StartupRole) => Promise<StartupRole>) => React.ReactNode;
}

const SchemaField = createSchemaField({
    components: {
        FormItem,
        Input,
        ArrayItems,
        Space,
        Select,
    },
});

const RoleForm: React.FC<RoleFormProps> = ({ roleId, buttons }) => {
    const { t } = useTranslation('start-up');
    const { t: ct } = useTranslation('common');

    return (
        <Form >
            <FormLayout labelCol={6} wrapperCol={18}>
                <SchemaField>
                    <SchemaField.String
                        name="name"
                        title={t('form.role.form.name')}
                        x-decorator="FormItem"
                        x-component="Input"
                        x-validator={[
                            {
                                required: true,
                                message: ct('validation.required'),
                            },
                        ]}
                    />
                </SchemaField>
                <SchemaField>
                    <SchemaField.String
                        name="action_completion_form_id"
                        title={t('form.role.form.action_completion_form_id')}
                        x-decorator="FormItem"
                        x-component="Select"
                        x-component-props={{
                            placeholder: ct('input.select.placeholder'),
                            allowClear: true,
                        }}
                    />
                </SchemaField>
                ,
                {
                    buttons((data: StartupRole) => {
                        if (data.action_completion_form_id === undefined) {
                            data.action_completion_form_id = null;
                        }

                        return upsertStartupRole(data).then((res) => {
                            message.success(ct('message.success'));

                            return res.data;
                        }).catch((error) => {
                            message.error('message.error');

                            throw error;
                        })
                    })
                }
            </FormLayout>
        </Form>
    );
};

export default RoleForm;
