import React from 'react';
import RoleForm from '../components/RoleForm';
import { StartupRole } from '../../../../types';

type EditRoleFormProps = {
    roleId?: number;
    buttons: (onSubmit: (data: StartupRole) => Promise<StartupRole>) => React.ReactNode;
}

const EditRoleForm: React.FC<EditRoleFormProps> = ({ roleId, buttons }) => {
    return <RoleForm roleId={roleId} buttons={buttons} />;
};

export default EditRoleForm;
