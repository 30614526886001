import React, { useMemo, useState } from 'react';
import { createForm } from '@formily/core';
import { createSchemaField } from '@formily/react';
import {
    Form,
    FormItem,
    FormLayout,
    Input,
    Select,
    Cascader,
    DatePicker,
    Submit,
    FormGrid,
    ArrayItems,
    Editable,
    FormButtonGroup,
} from '@formily/antd-v5';
import { Card, Spin, Button, Popconfirm, message } from 'antd';
import { EditOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import CompanyItem from '../components/CompanyItem';
import RoleItem from '../components/RoleItem';
import { useMount, useRequest } from 'ahooks';
import { getEditStartup, putStartup } from '../../../../utils/network/api/v1';
import { onFieldValueChange } from '@formily/core/esm/effects/onFieldEffects';
import './styles.css';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 0',
    },
    exclamationIcon: {
        color: 'red',
        marginRight: 3,
        cursor: 'default',
    },
};

const SchemaField = createSchemaField({
    components: {
        FormItem,
        FormGrid,
        FormLayout,
        Input,
        DatePicker,
        Cascader,
        Select,
        ArrayItems,
        Editable,
        EditOutlined,
        CompanyItem,
        RoleItem,
    },
});

const StartupForm: React.FC = () => {
    const { t } = useTranslation('start-up');
    const { t: ct } = useTranslation('common');
    const [mainFormModified, setMainFormModified] = useState<boolean>(false);

    const form = useMemo(() => createForm({
        effects() {
            onFieldValueChange(/account_name|work_areas/, () => {
                setMainFormModified(form.modified);
            });
        },
    }), []);

    const { loading, runAsync } = useRequest(getEditStartup, {
        manual: true,
    });
    const [saving, setSaving] = useState<boolean>(false);
    const busy = loading || saving;

    useMount(() => {
        runAsync().then((data) => form.setInitialValues(data));
    });

    return (
        <div
            style={styles.container}
        >
            <Card
                title={t('form.title')}
                extra={
                    <>
                        {mainFormModified && (
                            <span style={{ userSelect: 'none', marginRight: 10 }}>
                                <ExclamationCircleOutlined style={styles.exclamationIcon} />
                                {t('form.modified')}
                            </span>
                        )}

                        <Popconfirm
                            title={ct('reset.question')}
                            description={ct('reset.description')}
                            onConfirm={() => form.reset()}
                            okText={ct('yes')}
                            okButtonProps={{ danger: true, loading: busy }}
                            cancelText={ct('no')}
                            placement="right"
                        >
                            <Button
                                type="link"
                                size="small"
                            >
                                <ReloadOutlined spin={loading} />
                            </Button>
                        </Popconfirm>
                    </>
                }
                style={{ width: 620 }}
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        labelCol={5}
                        wrapperCol={16}
                        onAutoSubmit={(data) => {
                            setSaving(true);
                            putStartup(data).then((res) => {
                                form.setValues(res, 'overwrite');
                                setMainFormModified(false);

                                message.success(ct('message.success'));
                            }).catch((res: AxiosError) => {
                                if (res.response?.status === 422) {
                                    // @ts-ignore
                                    message.error(res.response?.data?.message);

                                    return;
                                }

                                message.error(ct('message.error'));
                            }).finally(() => {
                                setSaving(false);
                            });
                        }}
                    >
                        <SchemaField>
                            <SchemaField.String
                                name="account_name"
                                title={t('form.account-name')}
                                x-decorator="FormItem"
                                x-component="Input"
                                x-placeholder={ct('input.text.placeholder')}
                                x-validator={[
                                    {
                                        required: true,
                                        message: ct('validation.required'),
                                    },
                                ]}
                            />
                            <SchemaField.Array
                                name="work_areas"
                                title={t('form.work-areas')}
                                x-decorator="FormItem"
                                x-component="ArrayItems"
                            >
                                <SchemaField.Object>
                                    <SchemaField.Void x-component="ArrayItems.Item">
                                        <SchemaField.String
                                            name="name"
                                            x-validator={[
                                                {
                                                    required: true,
                                                    message: ct('validation.required'),
                                                },
                                            ]}
                                            x-decorator="Editable"
                                            x-component="Input"
                                        />
                                        <SchemaField.Void
                                            x-decorator="FormItem"
                                            x-component="ArrayItems.Remove"
                                        />
                                    </SchemaField.Void>
                                </SchemaField.Object>
                                <SchemaField.Void
                                    x-component="ArrayItems.Addition"
                                    title={t('form.add-work-area')}
                                    x-component-props={{
                                        defaultValue: {
                                            name: t('form.new-work-area')
                                        },
                                    }}
                                />
                            </SchemaField.Array>
                            <SchemaField.Array
                                name="companies"
                                title={t('form.companies')}
                                x-decorator="FormItem"
                                x-component="ArrayItems"
                                x-validator={[
                                    {
                                        required: true,
                                        message: ct('validation.required'),
                                    },
                                ]}
                            >
                                <SchemaField.Object x-component="CompanyItem"/>
                                <SchemaField.Void
                                    x-component="ArrayItems.Addition"
                                    title={t('form.add-company')}
                                    x-component-props={{
                                        defaultValue: {
                                            name: t('form.new-company'),
                                            branches: [],
                                        },
                                    }}
                                />
                            </SchemaField.Array>
                            <SchemaField.Array
                                name="roles"
                                title={t('form.roles')}
                                x-decorator="FormItem"
                                x-component="ArrayItems"
                            >
                                <SchemaField.Object x-component="RoleItem" />
                                <SchemaField.Void
                                    x-component="ArrayItems.Addition"
                                    title={t('form.add-role')}
                                    x-component-props={{
                                        defaultValue: {
                                            name: t('form.new-role')
                                        },
                                    }}
                                />
                            </SchemaField.Array>
                        </SchemaField>
                        <FormButtonGroup.FormItem>
                            <Submit block size="large" disabled={busy} loading={saving}>
                                {ct('submit')}
                            </Submit>
                        </FormButtonGroup.FormItem>
                    </Form>
                </Spin>
            </Card>
        </div>
    );
};

export default StartupForm;
