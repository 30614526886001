import { CommonTranslations } from '../types';

const common: CommonTranslations = {
    id: 'Sn.',
    loading: 'Yükleniyor...',
    index: 'Sıra No.',
    create: 'Oluştur',
    add: 'Ekle',
    details: 'Detaylar',
    edit: 'Düzenle',
    ok: 'Tamam',
    'validation-error': 'Veri Doğrulama Hatası',
    reminder: {
        'main-form-submission': 'Lütfen ana formu kaydetmeyi unutmayın',
    },
    submit: 'Gönder',
    delete: 'Sil',
    yes: 'Evet',
    no: 'Hayır',
    since: '{{date}} tarinden beri',
    actions: 'İşlemler',
    print: 'Yazdır',
    day: {
        title: 'Gün',
        monday: 'Pazartesi',
        tuesday: 'Salı',
        wednesday: 'Çarşamba',
        thursday: 'Perşembe',
        friday: 'Cuma',
        saturday: 'Cumartesi',
        sunday: 'Pazar',
        short: {
            monday: 'Pzt',
            tuesday: 'Sal',
            wednesday: 'Çar',
            thursday: 'Per',
            friday: 'Cum',
            saturday: 'Cmt',
            sunday: 'Paz',
        },
    },
    download: {
        pdf: 'PDF İndir',
        'pdf-named': '{{name}} PDFini İndir',
        'in-progress': 'PDF oluşturuluyor...',
    },
    network: {
        'error': {
            'no-connection': 'İnternet bağlantınızı kontrol edin',
            '403': 'Bu işlemi gerçekleştirmek için yetkiniz yok',
            '404': 'Kaynak bulunamadı',
            '500': 'Sunucu isteğimizi yerine getiremedi',
            '503': 'Sunucuda bakım yapılıyor. Lüften az sonra tekrar deneyin',
        },
    },
    message: {
        success: 'İşlem başarılı',
        error: 'İşlem başarısız',
    },
    reset: {
        question: 'Değişiklikleri geri almak istediğinize emin misiniz?',
        description: 'Kaydedilmemiş değişikliklerinizı kaybedeceksiniz.',
    },
    validation: {
        'error-title': 'Veri Doğrulama Hatası',
        required: 'Bu alan zorunludur',
        'empty-fields': 'Lütfen tüm zorunlu alanları doldurun.',
    },
    input: {
        text: {
            placeholder: 'Lütfen bir değer girin...',
        },
        number: {
            placeholder: 'Lütfen bir sayı girin...',
        },
        select: {
            none: 'Hiçbiri',
            placeholder: 'Lütfen seçin...',
        },
        filter: {
            placeholder: 'Filtrelemek için seçin...',
        },
        date: {
            format: 'DD.MM.YYYY',
            placeholder: 'Lütfen seçin...',
        },
        tags: {
            placeholder: 'Lütfen yazın ve Enter tuşuna basın...',
        }
    },
    menu: {
        activities: {
            title: 'Aktiviteler',
            dashboard: {
                title: 'Gösterge Paneli',
            },
            'daily-report': {
                title: 'Güne Bakış',
            },
            documentation: {
                title: 'Dökümantasyon',
            },
        },
        planning: {
            title: 'Planlama',
            'personnel-planning': {
                title: 'Personel Planlama',
            },
            'supply-planning': {
                title: 'Tedarik Planlama',
            },
            'service-planning': {
                title: 'Servis Planlama',
            },
        },
        files: {
            title: 'Dosyalar',
            'personnel-files': {
                title: 'Personel Dosyaları',
            },
            'supply-files': {
                title: 'Tedarik Dosyaları',
            },
            'service-files': {
                title: 'Servis Dosyaları',
            },
        },
        costs: {
            title: 'Maliyetler',
            'personnel-costs': {
                title: 'Personel Maliyetleri',
            },
            'supply-costs': {
                title: 'Tedarik Maliyetleri',
            },
            'service-costs': {
                title: 'Servis Maliyetleri',
            }
        },
        finance: {
            title: 'Finans',
            'general-finances': {
                title: 'Genel Finans',
            },
            'personnel-finances': {
                title: 'Personel Finans',
            },
            'supply-finances': {
                title: 'Tedarik Finans',
            },
            'service-finances': {
                title: 'Servis Finans',
            },
        },
        warehouse: {
            title: 'Depo',
        },
        'start-up': {
            title: 'Başlangıç',
        },
    },
};

export default common;
