import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SuggestedActionBoxProps } from './types';
import styles from './styles';

const SuggestedActionBox: React.FC<SuggestedActionBoxProps> = ({
    title,
    icon,
    backgroundColor,
    link,
}) => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(link)} style={{ ...styles.container, backgroundColor }}>
            {icon}
            <br/>
            {title}
        </div>
    );
};

export default SuggestedActionBox;
