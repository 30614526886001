import React from 'react';
import {
    ArrayItems,
    FormButtonGroup,
    FormDrawer,
    Submit,
} from '@formily/antd-v5';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect, mapProps } from '@formily/react';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import styles from './styles';
import { RoleItemProps } from './types';

import EditRoleForm from '../../EditRoleForm';
import { StartupRole } from '../../../../../types';
import { getListActionForms } from '../../../../../utils/network/api/v1';
import { asyncDataSource } from '../../../../files/personnelFiles/components/PersonnelForm/utils';

const RoleItem: React.FC<RoleItemProps> = ({ onChange }) => {
    const items = (ArrayItems as any).useArray();
    const record = (ArrayItems as any).useRecord();
    const index = (ArrayItems as any).useIndex();
    const { t } = useTranslation('start-up');
    const { t: ct } = useTranslation('common');

    return (
        <div style={styles.container}>
            <span>
                {(!record?.id) && (
                    <>
                        <QuestionCircleOutlined style={styles.questionMarkOutlinedIcon}/>
                        &nbsp;
                    </>
                )}
                {record.name}
            </span>
            <div>
                <EditOutlined
                    style={styles.editButton}
                    onClick={() => {
                        const drawer = FormDrawer(t('form.role.form.title'), () => (
                            <EditRoleForm
                                roleId={record.id}
                                buttons={(onSubmit: (data: StartupRole) => Promise<StartupRole>) => (
                                    <FormButtonGroup align="right">
                                        {(!record.id) && (
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    items.field?.remove?.(index);
                                                    items.field?.onRemove?.(index);

                                                    drawer.close();
                                                }}
                                                danger
                                            >
                                                {ct('delete')}
                                            </Button>
                                        )}
                                        <Submit onSubmit={(data) => onSubmit(data).then(onChange)}>
                                            {ct('submit')}
                                        </Submit>
                                    </FormButtonGroup>
                                )}
                            />
                        ));

                        drawer.open({
                            values: { ...record },
                            effects: () => {
                                asyncDataSource(
                                    'action_completion_form_id', async () => (
                                        await getListActionForms('role', 'completion')
                                            .then(({ data }) => (
                                                data.map(({ name, id }) => ({
                                                    label: name,
                                                    value: id,
                                                }))
                                            ))
                                    ));
                            },
                        });
                    }}
                />
                {(!record.id) && (
                    <DeleteOutlined
                        style={styles.deleteButton}
                        onClick={() => {
                            items.field?.remove?.(index);
                            items.field?.onRemove?.(index);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const ConnectedRoleItem = connect(
    RoleItem,
    mapProps((props, field) => {
        return { ...props, field };
    }),
);

export default ConnectedRoleItem;
