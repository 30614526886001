const styles = {
    container: {
        minWidth: 200,
        flex: 1,
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-end',
    },
    didNotShowUp: { fontWeight: 'bold', color: 'red' },
    onTime: { color: 'green' },
    late: { color: 'red' },
    lateStart: { color: 'red' },
    planned: { color: 'blue' },
};


export default styles;
