import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import FirstRow from '../../../../Foundation/FirstRow/FirstRow';

import { StartTabProps } from './types';

const StartTab: React.FC<StartTabProps> = ({ action }) => {
    const { actual_start_date } = action;
    const [time, setTime] = useState<Dayjs>(
        actual_start_date || dayjs(),
    );

    return (
        <FirstRow time={time} onTimeChange={setTime} action={action}/>
    );
};

export default StartTab;
