import { Avatar } from 'antd';
import React from 'react';

type IdentityCardProps = {
    title: string;
    icon: string;
    subtitle_1: string;
};

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
    },
};

const IdentityCard: React.FC<IdentityCardProps> = ({ title, icon, subtitle_1 }) => {
    return (
        <div style={styles.container}>
            <div style={{ paddingRight: 10 }}>
                <Avatar src={icon} alt="Icon" size={64}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <b>{title}</b>
                <span>{subtitle_1}</span>
            </div>
        </div>
    );
}

export default IdentityCard;
