import queryString from 'query-string';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import axios from './../../axios';
import { AxiosProgressEvent, AxiosPromise } from 'axios';
import {
    LoginRequest,
    LoginResponse,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    ResetPasswordRequest,
    ResetPasswordResponse,
} from './types';
import {
    ListedData,
    EmployeeContractHistoryCollection,
    UpcomingBirthdayResponse,
    UpcomingIdentityDocumentExpirationDateResponse,
    UpcomingContractExpirationDateResponse,
    StartupResponse,
    StartupCompany,
    EmployeeViewResource,
    EmployeeMetaResource,
    EmployeeFormResource,
    UpcomingResidencePermitExpirationDateResponse,
    RoleResource,
    BranchResource,
    EmployeeDailyTimePlanCollection,
    StoreBranchWeeklyRoleTimePlanRequest,
    DeleteBranchWeeklyRoleTimePlanRequest,
    EmployeeUserResource,
    ActionResponse,
    DuplicateBranchWeeklyTimePlanRequest, ListedEmployeeData, StartupBranch, StartupRole,
    GeneralFinancesResponse,
} from '../../../../types';

// XDEBUG_SESSION_START=PHPSTORM

type Collection<T> = {
    data: T[];
    links: {
        first: string;
        last: string;
        prev: number | null;
        next: number | null;
    },
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        path: string;
        per_page: number;
        to: number;
        total: number;
        links: Array<{ active: boolean, label: string, url: string }>
    }
}

type EmployeeContractResource = {
    id: number;
    start_date: string;
    end_date: string;
    role: RoleResource;
    branch: BranchResource;
}

type EmployeeResource = {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    contracts: Array<EmployeeContractResource>
}

type BranchFinancesResource = {
    
}

export const postLogin = (
    params: LoginRequest,
): AxiosPromise<LoginResponse> => (
    axios.post('/identity/token', params)
);

export const postForgotPassword = (
    params: ForgotPasswordRequest,
): AxiosPromise<ForgotPasswordResponse> => (
    axios.post('/identity/forgot-password', params)
);

export const postResetPassword = (
    params: ResetPasswordRequest,
): AxiosPromise<ResetPasswordResponse> => (
    axios.post('/identity/reset-password', params)
);

export const getEmployees = (
    page: number = 1,
    perPage: number = 30,
    sortBy: string | null,
    sortOrder: string | null,
    filters: any,
): AxiosPromise<Collection<EmployeeResource>> => {
    let url = `/files/employee?page=${page}&per_page=${perPage}`;

    if (sortBy && sortOrder) {
        url += `&sort_by=${sortBy}&sort_order=${sortOrder}`;
    }

    const filterQueryString = queryString.stringify(filters, { arrayFormat: 'bracket' });
    url += `&${filterQueryString}`;

    return axios.get(url);
};

export const getBranchFinances = (
    page: number = 1,
    perPage: number = 30,
    sortBy: string | null,
    sortOrder: string | null,
    filters: any,
): AxiosPromise<Collection<BranchFinancesResource>> => {
    let url = '/finance/branch';

    if (sortBy && sortOrder) {
        url += `?sort_by=${sortBy}&sort_order=${sortOrder}`;
    }

    const filterQueryString = queryString.stringify(filters, { arrayFormat: 'bracket' });
    url += `&${filterQueryString}`;

    return axios.get(url);
}

export const getEmployee = (id: number): AxiosPromise<EmployeeViewResource> => (
    axios.get(`/files/employee/${id}`)
).then((res) => res.data);

export const getEmployeeMeta = (id: number): AxiosPromise<EmployeeMetaResource> => (
    axios.get(`/files/employee/${id}/meta`)
).then((res) => res.data);

export const getEmployeePdf = (id: number) => (
    axios.get(`/files/employee/${id}/pdf`, {
        responseType: 'blob',
    })
).then((res) => {
    const filename = res.headers['content-disposition'].split('filename=')[1].trim().replace(/"/g, '');
    const fileType = res.headers['content-type'];

    fileDownload(res.data, filename, fileType);

    return res;
});

export const getEmployeeContractHistory = (
    employeeId: number,
    id: number,
): AxiosPromise<EmployeeContractHistoryCollection> => (
    axios.get(`/files/employee/${employeeId}/contract-history/${id}`)
);

export const storeEmployee = (values: any): AxiosPromise<boolean> => (
    axios.post('/files/employee', values)
);

export const editEmployee = (id: number): AxiosPromise<EmployeeFormResource> => (
    axios.get(`/files/employee/${id}/edit`)
);

export const updateEmployee = (id: number, values: any): AxiosPromise<EmployeeFormResource> => (
    axios.put(`/files/employee/${id}`, values)
);

export const uploadEmployeeProfilePhoto = (
    file: File,
    config: { onUploadProgress: (e: AxiosProgressEvent) => void },
) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post('/files/employee/profile-photo', formData, {
        ...config,
        headers: {
            'content-type': 'multipart/form-data',
        },
    }).then((res) => res.data);
};

export const getListWorkAreas = (): AxiosPromise<ListedData[]> => (
    axios.get('/list/work-area')
);

export const getListBranches = (company_id?: number): AxiosPromise<ListedData[]> => (
    axios.get('/list/branch', {
        params: {
            company_id,
        },
    })
);

export const getListCompanies = (): AxiosPromise<ListedData[]> => (
    axios.get('/list/company')
);

export const getListRoles = (
    company_id?: number,
    branch_id?: number,
): AxiosPromise<ListedData[]> => (
    axios.get('/list/role', {
        params: {
            company_id,
            branch_id,
        },
    })
);

export const getListEmployeeByQueryString = (
    q: string,
    role_id?: number,
): AxiosPromise<ListedEmployeeData[]> => (
    axios.get('/list/search-employee', {
        params: { q, role_id },
    })
);

enum AutoCompleteValueType {
    skill = 'employee.skill',
    employeeHealthDisease = 'employee.health.disease',
    employeeHealthMedication = 'employee.health.medication',
    employeeHealthAddiction = 'employee.health.addiction',
    employeeSchoolEducation = 'employee.education.school',
}

export const getAutoCompleteRequest = (type: AutoCompleteValueType) =>
    (q: string): AxiosPromise<ListedData[]> => (
        axios.get(`/list/auto-complete/${type}`, {
            params: { q },
        })
    );

export const getListEmployeeSkills = getAutoCompleteRequest(AutoCompleteValueType.skill);
export const getListEmployeeHealthDiseases = getAutoCompleteRequest(AutoCompleteValueType.employeeHealthDisease);
export const getListEmployeeHealthMedications = getAutoCompleteRequest(AutoCompleteValueType.employeeHealthMedication);
export const getListEmployeeHealthAddictions = getAutoCompleteRequest(AutoCompleteValueType.employeeHealthAddiction);
export const getListEmployeeEducationSchool = getAutoCompleteRequest(AutoCompleteValueType.employeeSchoolEducation);

export const getUpcomingBirthdays = (): AxiosPromise<UpcomingBirthdayResponse> => (
    axios.get('/files/employee/lists/upcoming-birthdays')
);

export const getUpcomingIdentityDocumentExpirationDates = (): AxiosPromise<UpcomingIdentityDocumentExpirationDateResponse> => (
    axios.get('/files/employee/lists/upcoming-identity-document-expiration-dates')
);

export const getUpcomingContractExpirationDates = (): AxiosPromise<UpcomingContractExpirationDateResponse> => (
    axios.get('/files/employee/lists/upcoming-contract-expiration-dates')
);

export const getUpcomingResidencePermitExpirationDates = (): AxiosPromise<UpcomingResidencePermitExpirationDateResponse> => (
    axios.get('/files/employee/lists/upcoming-residence-permit-expiration-dates')
);

export const getEditStartup = (): AxiosPromise<StartupResponse> => (
    axios.get('/startup/edit')
).then((res) => res.data.data);

export const putStartup = (data: StartupResponse): AxiosPromise<StartupResponse> => (
    axios.put('/startup', data)
).then((res) => res.data.data);

export const postStartupCompany = (data: StartupCompany): AxiosPromise<StartupCompany> => (
    axios.post('/startup/company', data)
);

export const putStartupCompany = (id: number, data: StartupCompany): AxiosPromise<StartupCompany> => (
    axios.put(`/startup/company/${id}`, data)
);

export const upsertStartupCompany = (data: StartupCompany): AxiosPromise<StartupCompany> => (
    data.id ? putStartupCompany(data.id, data) : postStartupCompany(data)
);

export const postStartupRole = (data: StartupRole): AxiosPromise<StartupRole> => (
    axios.post('/startup/role', data)
);

export const putStartupRole = (id: number, data: StartupRole): AxiosPromise<StartupRole> => (
    axios.put(`/startup/role/${id}`, data)
);

export const upsertStartupRole = (data: StartupRole): AxiosPromise<StartupRole> => (
    data.id ? putStartupRole(data.id, data) : postStartupRole(data)
);

export const postStartupBranch = (data: any): AxiosPromise<StartupBranch> => (
    axios.post(`/startup/company/${data.company_id}/branch`, data)
);

export const putStartupBranch = (id: number, data: any): AxiosPromise<StartupBranch> => (
    axios.put(`/startup/company/${data.company_id}/branch/${id}`, data)
);

export const upsertStartupBranch = (data: StartupBranch): AxiosPromise<StartupBranch> => (
    data.id ? putStartupBranch(data.id, data) : postStartupBranch(data)
);

export const getBranchWeeklyTimePlan = (
    branchId: number,
    week: dayjs.Dayjs,
    type: string,
): AxiosPromise<EmployeeDailyTimePlanCollection> => (
    axios.get(`/planning/branch/${branchId}/employee/weekly-time-plan/${week.format('YYYY-WW')}?type=${type}`)
);

export const downloadPersonnelPlanningPDF = (
    branchId: number,
    week: dayjs.Dayjs,
    planType: string,
): AxiosPromise<Blob> => (
    axios.get(`/planning/branch/${branchId}/employee/weekly-time-plan/${week.format('YYYY-WW')}/pdf?type=${planType}`, {
        responseType: 'blob',
    })
);

export const downloadPersonnelPlanningPDFDetailed = (
    branchId: number,
    week: dayjs.Dayjs,
    planType: string,
): AxiosPromise<Blob> => (
    axios.get(`/planning/branch/${branchId}/employee/weekly-time-plan/${week.format('YYYY-WW')}/pdf-detailed?type=${planType}`, {
        responseType: 'blob',
    })
);

export const upsertBranchRoleWeeklyTimePlan = (
    branchId: number,
    week: dayjs.Dayjs,
    data: StoreBranchWeeklyRoleTimePlanRequest,
): AxiosPromise<EmployeeDailyTimePlanCollection> => (
    axios.post(`/planning/branch/${branchId}/employee/weekly-time-plan/${week.format('YYYY-WW')}`, data)
);

export const deleteBranchRoleWeeklyTimePlan = (
    branchId: number,
    week: dayjs.Dayjs,
    data: DeleteBranchWeeklyRoleTimePlanRequest,
): AxiosPromise<EmployeeDailyTimePlanCollection> => (
    axios.delete(`/planning/branch/${branchId}/employee/weekly-time-plan/${week.format('YYYY-WW')}`, { data })
);

export const postDuplicateBranchWeeklyTimePlan = (
    branchId: number,
    week: dayjs.Dayjs,
    data: DuplicateBranchWeeklyTimePlanRequest,
): AxiosPromise<EmployeeDailyTimePlanCollection> => (
    axios.post(`/planning/branch/${branchId}/employee/weekly-time-plan/${week.format('YYYY-WW')}/duplicate`, data)
);

export const getEmployeeUser = (employee: number): AxiosPromise<EmployeeUserResource> => (
    axios.get(`/files/employee/${employee}/user`)
);

export const deleteEmployeeUser = (employee: number): AxiosPromise<EmployeeUserResource> => (
    axios.delete(`/files/employee/${employee}/user`)
);

export const postStoreEmployeeUser = (employee: number, data?: any): AxiosPromise<EmployeeUserResource> => (
    axios.post(`/files/employee/${employee}/user`, data)
);

export const postEmployeeUserResetPassword = (employee: number): AxiosPromise<EmployeeUserResource> => (
    axios.post(`/files/employee/${employee}/user/reset-password`)
);

export const patchEmployeeUserPermission = (
    employee: number,
    name: string,
    granted: boolean,
): AxiosPromise<boolean> => (
    axios.patch(`/files/employee/${employee}/user/permission`, { name, granted })
);

export const getActions = (
    day: string,
    filters: any = null,
): AxiosPromise<ActionResponse> => (
    axios.get(`/day/${day}/action`, { params: { ...filters } })
);

export const getListActionForms = (actionableType: string, phase: string): AxiosPromise<Array<ListedData>> => (
    axios.get(`/list/action-form/${actionableType}/${phase}`)
);

export const getGeneralFınances = (
    startDate: string,
    endDate: string,
): AxiosPromise<GeneralFinancesResponse> => (
    axios.get(`/finance/general?start_date=${startDate}&end_date=${endDate}`)
);

export const getPersonnelFinances = (
    companyId: number,
    startDate: string,
    endDate: string,
): AxiosPromise<any> => (
    axios.get('/finance/employee', {
        params: {
            company_id: companyId || undefined,
            start_date: startDate,
            end_date: endDate,
        },
    })
);

export const getBranchPersonnelFinances = (
    branchId: number,
    startDate: string,
    endDate: string,
): AxiosPromise<any> => (
    axios.get(`/finance/branch/${branchId}/employee`, {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
    })
);
