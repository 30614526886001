import React, { useState } from 'react';
import { StatisticCard } from '@ant-design/pro-components';
import { PageContainer } from '@ant-design/pro-components';
import { DatePicker, Result, Table } from 'antd';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { getGeneralFınances } from '../../../../utils/network/api/v1';

const { Operation } = StatisticCard;
const { RangePicker } = DatePicker;

const GeneralFinancesIndex: React.FC = () => {
    const startOfMonth = dayjs().startOf('month');
    const today = dayjs();

    const [state, setState] = useState<any>({
        dateRange: [startOfMonth, today],
    });

    const { data, error, loading } = useRequest(
        () => getGeneralFınances(state.dateRange[0].format('YYYY-MM-DD'), state.dateRange[1].format('YYYY-MM-DD')),
        {
            refreshDeps: [state.dateRange],
        }
    );

    const errorView = error ? (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
        />
    ) : null;

    const content = !(loading || error) ? (
        <Table
            pagination={false}
            bordered
            columns={[
                {
                    title: 'Şirket',
                    dataIndex: 'company_name',
                },
                {
                    title: 'Şube',
                    dataIndex: 'name',
                },
                {
                    title: 'Durum',
                    dataIndex: 'balance',
                    render: (text: string) => <span style={{ color: parseInt(text) > 0 ? '#3f8600' : '#cf1322' }}>{text}</span>,
                },
                {
                    title: 'Gelir',
                    dataIndex: 'income',
                    key: 'income',
                    render: (text: string) => <span style={{ color: '#3f8600' }}>{text}</span>,
                },
                {
                    title: 'Gider',
                    dataIndex: 'expense',
                    key: 'expense',
                    render: (text: string) => <span style={{ color: '#cf1322' }}>{text}</span>,
                },
            ]}
            dataSource={data!.data.branches}
            footer={() => (
                <StatisticCard.Group title="Genel Finansal Durum" bordered>
                    <StatisticCard
                        statistic={{
                            title: 'Durum',
                            value: data!.data.overall.balance,
                            precision: 2,
                            valueStyle: { color: data!.data.overall.balance > 0 ? '#3f8600' : '#cf1322' },
                            prefix: '€',
                        }}
                    />
                    <Operation>=</Operation>
                    <Operation>+</Operation>
                    <StatisticCard
                        statistic={{
                            title: 'Toplam Giren',
                            value: data!.data.overall.income,
                            precision: 2,
                            valueStyle: { color: '#3f8600' },
                            prefix: '€',
                        }}
                    />
                    <Operation>-</Operation>
                    <StatisticCard
                        statistic={{
                            title: 'Toplam Çıkan',
                            value: data!.data.overall.expense,
                            precision: 2,
                            valueStyle: { color: '#cf1322' },
                            prefix: '€',
                        }}
                    />
                </StatisticCard.Group>
            )}
        />
    ) : null;

    return (
        <>
            <PageContainer
                loading={loading}
                extra={[
                    <RangePicker
                        key="rangePicker"
                        style={{ marginRight: 16 }}
                        value={state.dateRange}
                        onChange={(dates) => {
                            setState((prevState: any) => ({
                                ...prevState,
                                dateRange: dates,
                            }))
                        }}
                    />,
                ]}
            >
                {error ? errorView : content}
            </PageContainer>
        </>
    );
};

export default GeneralFinancesIndex;