import { DashboardTranslations } from '../../types'

const dashboard: DashboardTranslations = {
    index: {
        action: {
            modal: {
                start: {
                    title: 'Entritt',
                },
                end: {
                    title: 'Ende',
                },
            },
            filters: {
                branch: {
                    label: 'Filiale',
                },
                date: {
                    label: 'Datum'
                },
            },
            empty: {
                text: 'Es gibt keine Aktionen für {{date}}.',
                suggestion: 'Bitte wählen Sie ein anderes Datum oder eine andere Filiale. Oder planen Sie Aktionen für diesen Tag.'
            },
            card: {
                description: {
                    'should_start': 'Sollte starten',
                    'is_start': 'Ist gestartet',
                    'should_end': 'Sollte enden',
                    'is_end': 'Ist beendet',
                },
                extra: {
                    'did-not-show-up': 'Hat gefehlt',
                    'on-time': 'Pünktlich',
                    'late': 'Spät',
                    'late-start': 'Später Start',
                    'planned': 'Geplant',
                },
            }
        }
    },
    'upcoming-birthdays': {
        title: 'Kommende Geburtstage',
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Kommende Passport Ablaufdaten',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Kommende Visaablaufdaten',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Kommende Vertragsablaufdaten',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Kommende Aufenthaltserlaubnisablaufdaten',
    },
};

export default dashboard;
