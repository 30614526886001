import React, { useState } from 'react';
import { StatisticCard } from '@ant-design/pro-components';
import { PageContainer } from '@ant-design/pro-components';
import { Tabs, DatePicker, Result, Select, Table, Button, Tag } from 'antd';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { getListCompanies, getPersonnelFinances } from '../../../../utils/network/api/v1';
import { AxiosError } from 'axios';
import { ResultStatusType } from 'antd/es/result';
import { CloseCircleOutlined } from '@ant-design/icons';
import BranchDetails from './BranchDetails';
import { colorfulMoney, colorfulMoneyColor, colorfulMoneyExpense, colorfulMoneyIncome } from '../../utils';

const { Operation } = StatisticCard;
const { RangePicker } = DatePicker;

const PersonnelFinancesIndex: React.FC = () => {
    const startOfMonth = dayjs().startOf('month');
    const today = dayjs();

    const [state, setState] = useState<any>({
        companyId: 0,
        dateRange: [startOfMonth, today],
        tabs: [],
        activeTab: '1',
    });

    const { data, error, loading } = useRequest(
        () => getPersonnelFinances(
            state.companyId,
            state.dateRange[0].format('YYYY-MM-DD'),
            state.dateRange[1].format('YYYY-MM-DD'),
        ),
        { refreshDeps: [state.companyId, state.dateRange] },
    );

    const { data: companies, error: errorCompanies, loading: loadingCompanies } = useRequest(
        () => getListCompanies(),
    );

    const errorView = error ? (
        <Result
            status={(((error as AxiosError).response?.status || 500) as ResultStatusType)}
            title="Error"
            subTitle="Sorry, something went wrong."
        />
    ) : null;

    const addTab = (branchId: number, branchName: string) => {
        const key = String((state.tabs || []).length + 2);

        // Check if tab already exists
        // If it does, set it as active.
        const existingBranchTab = state.tabs.find(
            (tab: any) => (
                tab.branchId === branchId
                && tab.startDate === state.dateRange[0].format('YYYY-MM-DD')
                && tab.endDate === state.dateRange[1].format('YYYY-MM-DD')
            )
        );

        if (existingBranchTab) {
            setState({
                ...state,
                activeTab: existingBranchTab.key,
            });
            
            return;
        }

        // Add new tab
        // And set it as active.
        setState({
            ...state,
            tabs: [
                ...state.tabs,
                {
                    key: key,
                    branchId,
                    startDate: state.dateRange[0].format('YYYY-MM-DD'),
                    endDate: state.dateRange[1].format('YYYY-MM-DD'),
                    label: (
                        <>
                            {/* <Badge color="gray" count="F" /> */}
                            &nbsp;
                            {branchName}
                            <Tag style={{ margin: 0, marginLeft: 5 }}>{state.dateRange[0].format('DD.MM')}-{state.dateRange[1].format('DD.MM')}</Tag>
                        </>
                    ),
                    children: <BranchDetails branchId={branchId} dateRange={state.dateRange} />,
                },
            ],
            activeTab: key,
        });
    };

    const onEditTab = (e: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
        if (e === '1') return;
        
        if (action === 'remove') {
            setState({
                ...state,
                tabs: state.tabs.filter((tab: any) => tab.key !== e),
                activeTab: state.activeTab === e ? '1' : state.activeTab,
            });
        }
    }

    const content = !(error || loading) ? (
        <Table
            loading={loading}
            pagination={false}
            bordered
            columns={[
                {
                    title: 'Şirket',
                    dataIndex: 'company_name',
                    key: 'company_name',
                },
                {
                    title: 'Şube',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Durum',
                    dataIndex: 'balance',
                    key: 'balance',
                    render: colorfulMoney,
                },
                {
                    title: 'Gelir',
                    dataIndex: 'income',
                    key: 'income',
                    render: colorfulMoneyIncome,
                },
                {
                    title: 'Gider',
                    dataIndex: 'expense',
                    key: 'expense',
                    render: colorfulMoneyExpense,
                },
                {
                    title: 'Aksiyon',
                    key: 'action',
                    render: (o) => (
                        <Button
                            type="link"
                            onClick={() => addTab(o.id, o.name)}
                        >
                            Detaylar
                        </Button>
                    ),
                }
            ]}
            dataSource={data?.data.branches || []}
            rowKey={(record) => record.id}
            footer={() => (
                <StatisticCard.Group title="Personel Finansal Durumu" bordered>
                    <StatisticCard
                        statistic={{
                            title: 'Durum',
                            value: data!.data.overall.balance,
                            precision: 2,
                            valueStyle: { color: colorfulMoneyColor(data!.data.overall.balance) },
                            prefix: '€',
                        }}
                    />
                    <Operation>=</Operation>
                    <Operation>+</Operation>
                    <StatisticCard
                        statistic={{
                            title: 'Toplam Giren',
                            value: data!.data.overall.income,
                            precision: 2,
                            valueStyle: { color: '#3f8600' },
                            prefix: '€',
                        }}
                    />
                    <Operation>-</Operation>
                    <StatisticCard
                        statistic={{
                            title: 'Toplam Çıkan',
                            value: data!.data.overall.expense,
                            precision: 2,
                            valueStyle: { color: '#cf1322' },
                            prefix: '€',
                        }}
                    />
                </StatisticCard.Group>
            )}
        />
    ) : null;

    
    return (
        <>
            <PageContainer
            loading={loading}
                extra={[
                    <Select
                        key="select"
                        defaultValue="all"
                        style={{ width: 120, marginRight: 16 }}
                        loading={loadingCompanies}
                        suffixIcon={errorCompanies ? <CloseCircleOutlined style={{ color: '#ff4d4f' }} /> : undefined}
                        value={state.companyId}
                        options={
                            [
                                {
                                    label: 'Hepsi',
                                    value: 0,
                                },
                            ].concat(
                                companies?.data.map((company: any) => ({ label: company.name, value: company.id })) || [],
                            )
                        }
                        onChange={(value) => {
                            setState((prevState: any) => ({
                                ...prevState,
                                companyId: value,
                            }))
                        }}
                    />,
                    <RangePicker
                        key="rangePicker"
                        style={{ marginRight: 16 }}
                        value={state.dateRange}
                        onChange={(dates) => {
                            setState((prevState: any) => ({
                                ...prevState,
                                dateRange: dates,
                            }))
                        }}
                    />,
                ]}
            >
                {error ? errorView : (
                    <Tabs
                        tabBarStyle={{ marginBottom: 0 }}
                        activeKey={state.activeTab}
                        defaultActiveKey="1"
                        type="editable-card"
                        onEdit={onEditTab}
                        hideAdd
                        items={[
                            {
                                key: '1',
                                label: 'Genel',
                                children: (
                                    <div
                                        style={{
                                            padding: 5,
                                            paddingTop: 16,
                                            backgroundColor: '#fff',
                                            border: '1px solid #f0f0f0',
                                            borderTop: 'none',
                                        }}>
                                        {content}
                                    </div>
                                ),
                            },
                            ...state.tabs.map((tab: any) => ({
                                key: tab.key,
                                label: tab.label,
                                children: (
                                    <div
                                        style={{
                                            padding: 5,
                                            paddingTop: 16,
                                            paddingBottom: 16,
                                            backgroundColor: '#fff',
                                            border: '1px solid #f0f0f0',
                                            borderTop: 'none',
                                        }}
                                    >
                                        {tab.children}
                                    </div>
                                ),
                            })),
                        ]}
                        onChange={(key) => {
                            setState({
                                ...state,
                                activeTab: key,
                            })
                        }}
                    />
                        
                )}
            </PageContainer>
        </>
    );
};

export default PersonnelFinancesIndex;