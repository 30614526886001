import { StartUpTranslations } from '../../types';

const startUp: StartUpTranslations = {
    form: {
        title: 'Start-Up & Unternehmensverwaltung',
        'account-name': 'Kontoname',
        companies: 'Unternehmen',
        'new-company': 'Neues Unternehmen',
        'add-company': 'Unternehmen hinzufügen',
        company: {
            form: {
                title: 'Unternehmen bearbeiten',
                name: 'Name',
                branches: 'Filialen',
                'new-branch': {
                    title: 'Neues Fillialle',
                    'payment-methods': {
                        'defaults': {
                            'cash': {
                                'name': 'Bar',
                            },
                        },
                    },
                },
                'add-branch': 'Fillialle hinzufügen',
            },
            branch: {
                title: 'Fillialle bearbeiten',
                name: 'Name',
                'payment-methods': {
                    title: 'Kunden-Zahlungsmethoden',
                    'add': 'Zahlungsmethode hinzufügen',
                    'default-name': 'Neue Zahlungsmethode',
                },
                'timezone': 'Zeitzone',
            },
        },
        'work-areas': 'Arbeitsbereiche',
        'new-work-area': 'Neuer Arbeitsbereich',
        'add-work-area': 'Arbeitsbereich hinzufügen',
        roles: 'Rollen',
        role: {
            form: {
                'name': 'Name',
                'title': 'Rolle bearbeiten',
                'action_completion_form_id': 'Werktagsabschlussformular',
            },
        },
        'add-role': 'Rolle hinzufügen',
        'new-role': 'Neue Rolle',
        modified: 'Bearbeitet',
    },
};

export default startUp;
