import { StartUpTranslations } from "../../types";

const startUp: StartUpTranslations = {
    form: {
        title: 'Start-Up & Şirket Yönetimi',
        'account-name': 'Hesap Adı',
        companies: 'Şirketler',
        'new-company': 'Yeni Şirket',
        'add-company': 'Şirket Ekle',
        company: {
            form: {
                title: 'Şirket Düzenle',
                name: 'Adı',
                branches: 'Şubeler',
                'new-branch': {
                    title: 'Yeni Şube',
                    'payment-methods': {
                        'defaults': {
                            'cash': {
                                'name': 'Nakit'
                            }
                        }
                    }
                },
                'add-branch': 'Şube Ekle',
            },
            branch: {
                title: 'Şube Düzenle',
                name: 'Adı',
                'payment-methods': {
                    title: 'Müşteri Ödeme Yöntemleri',
                    'add': 'Ödeme Yöntemi Ekle',
                    'default-name': 'Yeni Ödeme Yöntemi',
                },
                'timezone': 'Zaman Dilimi',
            },
        },
        'work-areas': 'Çalışma Alanları',
        'new-work-area': 'Yeni Çalışma Alanı',
        'add-work-area': 'Çalışma Alanı Ekle',
        'roles': 'Roller',
        'role': {
            form: {
                name: 'Adı',
                title: 'Rolü Düzenle',
                'action_completion_form_id': 'İş Günü Tamamlama Formu',
            },
        },
        'add-role': 'Rol Ekle',
        'new-role': 'Yeni Rol',
        modified: 'Değiştirilmiş',
    },
};

export default startUp;
