import React from 'react';
import dayjs from 'dayjs';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { ExtraProps } from './types';

const Extra: React.FC<ExtraProps> = ({ action }) => {
    const { t } = useTranslation('dashboard', {
        keyPrefix: 'index.action.card.extra',
    });

    const now = dayjs(new Date());
    const {
        scheduled_start_date,
        actual_start_date,
        scheduled_end_date,
        actual_end_date,
    } = action;

    const scheduledStartDate = dayjs(scheduled_start_date);
    const actualStartDate = actual_start_date ? dayjs(actual_start_date) : null;
    const scheduledEndDate = dayjs(scheduled_end_date);
    const actualEndDate = actual_end_date ? dayjs(actual_end_date) : null;

    const shouldStart = now.isAfter(scheduledStartDate)
        || now.isSame(scheduledStartDate);
    const started = !!actualStartDate;

    const goodStart = actualStartDate?.isBefore(scheduledStartDate)
        || actualStartDate?.isSame(scheduledStartDate);

    const isLate = shouldStart && !actualStartDate;
    const lateStart = started && !goodStart;

    const shouldEnd = now.isAfter(scheduled_end_date) || now.isSame(scheduled_end_date);
    const ended = !!actual_end_date;
    // const goodEnd = actualEndDate?.isAfter(scheduledEndDate) || actualEndDate?.isSame(scheduledEndDate);
    // const isLateEnd = shouldEnd && !actualEndDate;
    const didntWork = shouldStart && shouldEnd && !started && !ended;

    const workHours = scheduledEndDate.diff(scheduledStartDate, 'hour');

    let completed;
    if (actualEndDate) {
        completed = actualEndDate.diff(dayjs(actualStartDate), 'hour');
    } else if (actualStartDate) {
        completed = now.diff(dayjs(actualStartDate), 'hour');
    } else {
        completed = 0;
    }

    const percentage = parseFloat(Number((completed / workHours) * 100).toFixed(0));

    return (
        <div style={styles.container}>
            <div style={{ width: 200 }}>
                {didntWork && <span style={styles.didNotShowUp}>{t('did-not-show-up')}</span>}
                {
                    !didntWork && shouldStart && (
                        <>
                            {goodStart && <span style={styles.onTime}>{t('on-time')}</span>}
                            {isLate && <span style={styles.late}>{t('late')}</span>}
                            {lateStart && <span style={styles.lateStart}>{t('late-start')}</span>}
                        </>
                    )
                }
                {!shouldStart && <span style={styles.planned}>{t('planned')}</span>}
            </div>
            <Progress percent={percentage}/>
        </div>
    );
};

export default Extra;
