const styles = {
    subtitleContainer: {
        marginTop: -6,
        marginBottom: 10,
    },
    timeInfoRow: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    firstInfoColumn: { minWidth: 170 },
};

export default styles;
