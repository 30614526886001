import { DashboardTranslations } from '../../types';

const dashboard: DashboardTranslations = {
    'index': {
        action: {
            modal: {
                start: {
                    title: 'Başlat',
                },
                end: {
                    title: 'Bitir',
                },
            },
            filters: {
                branch: {
                    label: 'Şube',
                },
                date: {
                    label: 'Tarih'
                },
            },
            empty: {
                text: '{{date}} tarihi için bir eylem yok.',
                suggestion: 'Lütfen başka bir tarih veya şube seçin. Veya bu gün için eylemler planlayın.',
            },
            card: {
                description: {
                    'should_start': 'Başlamalı',
                    'is_start': 'Başladı',
                    'should_end': 'Bitmeli',
                    'is_end': 'Bitti',
                },
                extra: {
                    'did-not-show-up': 'Gelmedi',
                    'on-time': 'Zamanında',
                    'late': 'Geç',
                    'late-start': 'Geç başlangıç',
                    'planned': 'Planlandı',
                },
            },
        },
    },
    'upcoming-birthdays': {
        title: 'Yaklaşan Doğum Günleri',
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Yaklaşan Pasaport Bitiş Tarihleri',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Yaklaşan Vize Bitiş Tarihleri',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Yaklaşan Sözleşme Bitiş Tarihleri',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Yaklaşan İkamet İzni Bitiş Tarihleri',
    },
};

export default dashboard;
