const colorZero = '#1890ff';
const colorPositive = '#3f8600';
const colorNegative = '#cf1322';

export const colorfulMoneyColor = (val: any) => {
    // initial value is blue
    var color = colorZero;;

    // if balance is positive, set color to green
    if (parseInt(val) > 0) {
        color = colorPositive;
    }

    // if balance is negative, set color to red
    if (parseInt(val) < 0) {
        color = colorNegative;
    }

   return color;
}


export const colorfulMoney = (text: string) => {
   const color = colorfulMoneyColor(text);
   
   return <span style={{ color }}>{text}</span>
}

export const colorfulMoneyIncome = (text: string) => {
   return <span style={{ color: colorPositive }}>{text}</span>
}

export const colorfulMoneyExpense = (text: string) => {
   return <span style={{ color: colorNegative }}>{text}</span>
}