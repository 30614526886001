import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import FirstRow from '../../../../Foundation/FirstRow/FirstRow';

import { EndTabProps } from './types';

const EndTab: React.FC<EndTabProps> = ({ action }) => {
    const { actual_start_date } = action;
    const [time, setTime] = useState<Dayjs>(
        actual_start_date || dayjs(),
    );

    return (
        <FirstRow time={time} onTimeChange={setTime} action={action}/>
    );
};

export default EndTab;
