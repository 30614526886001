import React from 'react';
import { TimePicker } from '@formily/antd-v5';

import styles from './styles';
import { TimeViewProps } from './types';

const TimeView: React.FC<TimeViewProps> = ({ value, onChange }) => {
    return (
        <div style={styles.container}>
            <div>
                <TimePicker
                    value={value}
                    format="HH:mm"
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default TimeView;
