import React from 'react';
import { Navigate, Route, Routes as BaseRoutes } from 'react-router-dom';

import { Auth, Rbac } from '../components/Auth';
import Pro from '../layout/ProLayout';
import Login from '../pages/identity/Login';
import Analysis from '../pages/dashboard/Analysis';
import PersonnelFileIndex from '../pages/files/personnelFiles/PersonnelFileIndex';
import NewPersonnelForm from '../pages/files/personnelFiles/NewPersonnelForm';
import EditPersonnelForm from '../pages/files/personnelFiles/EditPersonnelForm';
import StartupIndex from '../pages/startup/StartupIndex';
import PersonnelDetails from '../pages/files/personnelFiles/PersonnelDetails';
import PersonnelPlanningIndex from '../pages/planning/personnelPlanning/PersonnelPlanningIndex';
import ForgotPassword from '../pages/identity/ForgotPassword';
import ResetPassword from '../pages/identity/ResetPassword';
import TodayIndex from '../pages/dashboard/TodayIndex';
import GeneralFinancesIndex from '../pages/finance/generalFinances/GeneralFinancesIndex';
import PersonnelFinancesIndex from '../pages/finance/personnelFinances/PersonnelFinancesIndex';

const Routes: React.FC = () => {
    return (
        <BaseRoutes>
            <Route
                path="/"
                element={
                    <Navigate to={'/activities/dashboard'}/>
                }
            />
            <Route
                path="/activities"
                element={
                    <Navigate to={'/activities/dashboard'}/>
                }
            />
            <Route
                path="/activities/dashboard"
                element={
                    <Auth>
                        <Pro>
                            <Analysis/>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/activities/daily-report"
                element={
                    <Auth>
                        <Pro>
                            <TodayIndex/>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/activities/documentation"
                element={
                    <Auth>
                        <Pro>
                            <div>Documentation</div>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/planning/personnel"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="employee-planning:access">
                                <PersonnelPlanningIndex />
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/planning/supply"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="supply-planning:access">
                                <div>Supply Planning</div>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/planning/service"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="service-planning:access">
                                <div>Service Planning</div>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/files/personnel"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="employee-files:access">
                                <PersonnelFileIndex/>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/files/personnel/new"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="employee-files:access">
                                <NewPersonnelForm/>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/files/personnel/:id"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="employee-files:access">
                                <PersonnelDetails/>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/files/personnel/:id/edit"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="employee-files:access">
                                <EditPersonnelForm/>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/files/supply"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="supply-files:access">
                                <div>Supply Files</div>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/files/service"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="service-files:access">
                                <div>Service Files</div>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/finance/general"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="general-finances:access">
                                <GeneralFinancesIndex />
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/finance/personnel"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="personnel-finances:access">
                                <PersonnelFinancesIndex />
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/finance/supply"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="supply-finances:access">
                                <div>Supply Finances</div>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/finance/service"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="service-finances:access">
                                <div>Service Finances</div>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/warehouse"
                element={
                    <Auth>
                        <Pro>
                            <div>Warehouse</div>
                        </Pro>
                    </Auth>
                }
            />
            <Route
                path="/start-up"
                element={
                    <Auth>
                        <Pro>
                            <Rbac permission="organization-management:access">
                                <StartupIndex/>
                            </Rbac>
                        </Pro>
                    </Auth>
                }
            />

            <Route
                path="/identity/login"
                element={<Login/>}
            />
            <Route
                path="/identity/forgot-password"
                element={<ForgotPassword />}
            />
            <Route
                path="identity/reset-password/:token"
                element={<ResetPassword />}
            />
        </BaseRoutes>
    );
};

export default Routes;
