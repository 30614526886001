const styles = {
    loadingContainer: {
        height: 32,
        paddingTop: 4,
        verticalAlign: 'middle',
    },
    loading: { paddingRight: 5 },
    branchSelect: { width: 200, marginRight: 20 },
    datePicker: { marginRight: 20 },
    suggestedActionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
};

export default styles;
