import React from 'react';
import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { ActionModalProps } from './types';

import StartTab from './ActionCards/PersonnelActionCards/StartTab';
import EndTab from './ActionCards/PersonnelActionCards/EndTab';

const ActionModal: React.FC<ActionModalProps> = ({
    open,
    handleOk,
    confirmLoading,
    handleCancel,
    action,
}) => {
    const { t } = useTranslation('dashboard', {
        keyPrefix: 'index.action.modal',
    });

    const items = [
        {
            key: 'start',
            label: t('start.title'),
            children: <StartTab action={action}/>,
        },
        {
            key: 'end',
            label: t('end.title'),
            children: <EndTab action={action}/>,
        },
    ];

    return (
        <Modal
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <Tabs defaultActiveKey="start" items={items}/>
        </Modal>
    );
};

export default ActionModal;
