import { useRequest } from "ahooks";
import { Result, Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import { getBranchPersonnelFinances } from "../../../../../utils/network/api/v1";
import { colorfulMoney, colorfulMoneyIncome, colorfulMoneyExpense } from "../../../utils";

type BranchDetailsProps = {
    branchId: number;
    dateRange: [dayjs.Dayjs, dayjs.Dayjs];
};

const BranchDetails: React.FC<BranchDetailsProps> = ({
    branchId,
    dateRange,
}: BranchDetailsProps) => {
    const [state] = React.useState({
        dateRange,
    });
    
    const { data, error, loading } = useRequest(
        () => getBranchPersonnelFinances(
            branchId,
            state.dateRange[0].format('YYYY-MM-DD'),
            state.dateRange[1].format('YYYY-MM-DD'),
        ),
        { refreshDeps: [branchId, state.dateRange] },
    );

    if (error) {
        return <Result status="error" title="Error" subTitle={error.message} />;
    }
    
    return (
        <Table
            loading={loading}
            bordered
            columns={[
                {
                    title: "Name",
                    dataIndex: "full_name",
                    key: "full_name",
                },
                {
                    title: "Role",
                    dataIndex: "role_name",
                    key: "role_name",
                },
                // {
                    // title: "Gehalt",
                    // dataIndex: "salary",
                    // key: "salary",
                // },
                {
                    title: "Income",
                    dataIndex: "income",
                    key: "income",
                    render: colorfulMoneyIncome,
                },
                {
                    title: "Expense",
                    dataIndex: "expense",
                    key: "expense",
                    render: colorfulMoneyExpense,
                },
                {
                    title: "Balance",
                    dataIndex: "balance",
                    key: "balance",
                    render: colorfulMoney,
                },
                // {
                    // title: "Aktionen",
                    // key: "actions",
                    // render: () => (
                        // <Button type="link">Gussteile</Button>
                    // ),
                // },
            ]}
            dataSource={data?.data}
            pagination={false}
        />
    );
};

export default BranchDetails;